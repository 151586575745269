<!--
 * @Description: 运维管理 运维管理 运维工单管理 operateUnit
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 11:51:12
 * @LastEditors: zhoucheng
-->
<template>
  <div class="operateUnit-mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="单位编号">
              <el-input v-model="searchForm.maintenanceId"
                        placeholder="请输入单位编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="单位名称">
              <el-input v-model="searchForm.maintenanceName"
                        placeholder="请输入单位名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="单位联系人">
              <el-input v-model="searchForm.contact"
                        placeholder="请输入单位联系人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="单位联系电话">
              <el-input v-model="searchForm.contactPhoneNumber"
                        placeholder="请输入单位联系电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="handleClickReset">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>运维单位管理</span>
        <el-row class="topButton tableTitleButton">
          <el-button type="primary"
                     @click="handleAdd">新 增</el-button>
          <!-- <el-button type="info"
                     style="margin-left:17px">导入</el-button>
          <el-button type="info"
                     style="margin-left:17px">导 出</el-button>
          <el-button type="danger"
                     style="margin-left:17px">批量删除</el-button> -->
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="运维设备">
              <template slot-scope="scope">
                <el-button @click="handleClickBindList(scope.row)"
                           type="text"
                           size="medium">绑定设备清单</el-button>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickEdit(scope.row)">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增"
               :visible.sync="addDialogVisible"
               append-to-body
               @close="popClose">
      <el-form label-width="150px"
               :model="addForm"
               :rules="rules"
               ref="addForm"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位编号"
                          prop="maintenanceId">
              <el-input v-model="addForm.maintenanceId"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位名称"
                          prop="maintenanceName">
              <el-input v-model="addForm.maintenanceName"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位地址"
                          prop="address">
              <el-input v-model="addForm.address"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位联系人"
                          prop="contact">
              <el-input v-model="addForm.contact"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位联系人电话"
                          prop="contactPhoneNumber">
              <el-input v-model="addForm.contactPhoneNumber"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addForm.remark"
                        class="width input dt-form-width"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClickAddConfirm('addForm')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="addDialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改"
               :visible.sync="editDialogVisible"
               append-to-body
               @close="queryOperateUnitList">
      <el-form label-width="150px"
               :model="editForm"
               :rules="rules"
               ref="addForm"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位编号"
                          prop="maintenanceId">
              <el-input v-model="editForm.maintenanceId"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位名称"
                          prop="maintenanceName">
              <el-input v-model="editForm.maintenanceName"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位地址"
                          prop="address">
              <el-input v-model="editForm.address"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位联系人"
                          prop="contact">
              <el-input v-model="editForm.contact"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位联系人电话"
                          prop="contactPhoneNumber">
              <el-input v-model="editForm.contactPhoneNumber"
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="editForm.remark"
                        class="width input dt-form-width"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClickEditConfirm()"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="editDialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog title="详情"
               :visible.sync="watchDialogVisible"
               append-to-body>
      <el-form label-width="150px"
               :model="watchForm"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位编号"
                          prop="maintenanceId">
              <el-input v-model="watchForm.maintenanceId"
                        readonly
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位名称"
                          prop="maintenanceName">
              <el-input v-model="watchForm.maintenanceName"
                        readonly
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位地址"
                          prop="address">
              <el-input v-model="watchForm.address"
                        readonly
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位联系人"
                          prop="contact">
              <el-input v-model="watchForm.contact"
                        readonly
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="单位联系人电话"
                          prop="contactPhoneNumber">
              <el-input v-model="watchForm.contactPhoneNumber"
                        readonly
                        class="width input dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="watchForm.remark"
                        readonly
                        class="width input dt-form-width"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button @click="watchDialogVisible = false"
                     type="info">返 回</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'maintenanceId',
          label: '单位编号',
        },
        {
          prop: 'maintenanceName',
          label: '单位名称',
        },
        {
          prop: 'contactPhoneNumber',
          label: '单位联系电话',
        },
        {
          prop: 'contact',
          label: '单位联系人',
        },
        {
          prop: 'address',
          label: '单位地址',
        }
      ],
      tableList: {
        list: [],
      },
      // 搜索表单
      searchForm: {
        maintenanceId: "",
        maintenanceName: "",
        contact: "",
        contactPhoneNumber: ""
      },
      pageNum: 1,
      pageSize: 15, // 初始化分页
      //新增表单
      rules: {
        maintenanceId: [{ required: true, message: '请输入', trigger: 'blur' }],
        maintenanceName: [{ required: true, message: '请输入', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      addDialogVisible: false, // 新增弹窗显示
      addForm: {},//新增表单
      watchDialogVisible: false, // 查看详情弹窗显示
      watchForm: {},//查看表单
      editDialogVisible: false,//修改弹窗显示
      editForm: {},//修改表单
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryOperateUnitList()
  },
  //方法集合
  methods: {
    // 默认表格查询
    queryOperateUnitList () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$operateUnit.queryMaintenanceList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryOperateUnitList()
    },
    // 查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryOperateUnitList()
    },
    // 重置按钮
    handleClickReset () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryOperateUnitList()
    },

    // 新增按钮
    handleAdd () {
      this.addDialogVisible = true
      this.addForm = {}
    },
    // 新增确定按钮
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$operateUnit.addMaintenance(this.addForm).then(() => {
            this.$message({ message: '新增成功', type: 'success' });
            this.addDialogVisible = false
            this.queryOperateUnitList()
          })
        }
      })
    },
    // 绑定设备清单
    handleClickBindList (row) {
      this.$router.push({ name: "operateUnitDev", query: { maintenanceId: row.maintenanceId } })
    },
    //修改按钮
    handleClickEdit (row) {
      this.editDialogVisible = true
      this.editForm = row
    },
    // 修改确认按钮
    handleClickEditConfirm () {
      this.$operateUnit.updateMaintenance(this.editForm).then(() => {
        this.$message({
          message: '修改成功', type: 'success'
        });
        this.queryOperateUnitList()
        this.editDialogVisible = false
      })
    },
    //删除
    handleClickDelete (row) {
      this.$confirm('是否删除该运维单位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$operateUnit.delMaintenance([{ maintenanceId: row.maintenanceId }])
          .then(() => {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryOperateUnitList()
          })
      })
    },
    // 点击查看详情
    handleClickDetail (row) {
      this.watchForm = row
      this.watchDialogVisible = true
    },
    popClose () {
      this.$refs.addForm.resetFields();
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类

// 最外层div
.operateUnit-mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 111px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 30px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.title {
  font-size: 16px;
  color: #70aee8;
  line-height: 18px;
}
.content {
  font-size: 14px;
  color: #bae8ff;
  line-height: 18px;
}
.lineOne {
  margin-top: 25px;
}
.imageBox {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
